<template>
  <MainLayout section="profile">
    <div id="profile">
      <div id="left" v-if="mq_gte_md"/>

      <div id="center">
        <div id="header">
          <b-button :class="{active : profile_active}"
                    @click="set_active_tab('profile')">
            My Profile
          </b-button>

          <b-button :class="{active : property_active}"
                    @click="set_active_tab('property')">
            My Property
          </b-button>

          <b-button :class="{active : corporations_active}"
                    @click="set_active_tab('corporations')">
            Corporations
          </b-button>
        </div>

        <ProfileTab  v-if="profile_active"  />
        <PropertyTab v-if="property_active" />
        <CorporationsTab v-if="corporations_active" />
      </div>

      <div id="right" v-if="mq_gte_md"/>
    </div>

    <PurchaseLCC  />
    <WithdrawLCC  />
    <DepositLCC   />
    <ClaimAccount />
    <ClaimVerification />
    <FormCorporation />
    <LeaveCorporation />
    <InviteToCorporation />
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout'
import ProfileTab from './components/profile/ProfileTab'
import PropertyTab from './components/profile/PropertyTab'
import CorporationsTab from './components/profile/CorporationsTab'

import PurchaseLCC from './components/profile/modals/PurchaseLCC'
import WithdrawLCC from './components/profile/modals/WithdrawLCC'
import DepositLCC from './components/profile/modals/DepositLCC'
import ClaimAccount from './components/profile/modals/ClaimAccount'
import ClaimVerification from './components/profile/modals/ClaimVerification'
import FormCorporation from './components/profile/modals/FormCorporation'
import LeaveCorporation from './components/profile/modals/LeaveCorporation'
import InviteToCorporation from './components/profile/modals/InviteToCorporation'

import Authentication from './mixins/authentication'
import ServerAPI from './mixins/server_api'
import TriggersModal from './mixins/triggers_modal'
import MaintenanceMode from './mixins/maintenance_mode'

export default {
  name: 'Profile',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal,
    MaintenanceMode
  ],

  components : {
    MainLayout,
    ProfileTab,
    PropertyTab,
    CorporationsTab,
    PurchaseLCC,
    WithdrawLCC,
    DepositLCC,
    ClaimAccount,
    ClaimVerification,
    FormCorporation,
    LeaveCorporation,
    InviteToCorporation
  },

  data () {
    return {
      active_tab : 'profile'
    }
  },

  computed : {
    profile_active () {
      return this.active_tab == 'profile';
    },

    property_active () {
      return this.active_tab == 'property';
    },

    corporations_active () {
      return this.active_tab == 'corporations';
    }
  },

  methods : {
    set_active_tab (tab){
      this.active_tab = tab;
      location.hash = "#" + tab;
    }
  },

  mounted () {
    if(this.maintenance_mode){
      this.logout();
      this.show_maintenance_dialog();
    }

    this.get_meta();
  },

  created () {
    if(!this.logged_in)
      this.$router.push("/")

    if(location.hash == '#profile')
      this.active_tab = 'profile';
    else if(location.hash == '#property' || location.hash == '#claim')
      this.active_tab = 'property';
    else if(location.hash == '#corporations')
      this.active_tab = 'corporations';
  }
}
</script>

<style scoped>
#profile {
  flex-grow: 1;
  display: flex;
  padding: 50px 0;
}

#left, #right{
  background-color: var(--theme-color7);
  opacity: 0.2;
  min-height: 100%;
  flex-basis: 15%;
  margin: 50px 0;
}

#main_layout.md #left,
#main_layout.md #right{
  flex-basis: 5%;
}

#left {
  margin-left: 50px;
  border-radius: 16px 0px 0 16px;
}

#right {
  margin-right: 50px;
  border-radius: 0px 16px 16px 0px;
}

#center{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid var(--theme-color17);
  border-radius: 16px;
}

#main_layout.sm #center,
#main_layout.xs #center{
  margin: 0 5px;
  padding: 10px 5px;
}

#header{
  background: var(--theme-color21);
  padding: 15px;
  border-radius: 16px;
}

#header button{
  width: 33%;
  background: none;
  color: var(--theme-color10);
  border: none;
  padding: 20px;
  font-size: 24px;
  border-radius: 8px;
}

#main_layout.sm #header button,
#main_layout.xs #header button {
  padding: 16px;
  font-size: 16px;
}

#header button.active{
  background: var(--theme-color0);
  color: var(--theme-color9);
  box-shadow: 0px 16px 38px var(--theme-color27);
  border-radius: 8px;
  font-weight: bold;
}
</style>

<style>
#profile .row{
  padding-top: 10px;
  display: flex;
  align-items: center;
}

#profile .row .header{
  color: var(--theme-color14);
  font-size: 1rem;
}

#profile .row .content{
  font-weight: bold;
  height: 38px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

#profile .row .error{
  color: var(--theme-color16);
}

#profile .row .error_placeholder{
  height: 18px;
}

#profile .row .button_col{
  display: flex;
  justify-content: flex-end;
}

#profile .row button{
  border-radius: 41px;
  background: none;
  padding: 5px 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

#profile .row button img{
  margin-left: 5px;
}

#profile .row button.edit {
  border: 1px solid var(--theme-color22);
  color: var(--theme-color9);
}

#profile .row button.edit img{
  height: 15px;
  width: 15px;
}

#profile .row button.cancel {
  border: 1px solid var(--theme-color23);
  color: var(--theme-color16);
}

#profile .row button.update{
  border: 1px solid var(--theme-color22);
  color: var(--theme-color9);
}

.profile_modal_header{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.profile_modal_label{
  font-weight: bold;
}

.profile_modal_required{
  color: var(--theme-color16);
}
</style>
