<template>
  <MainLayout section="guide">
    <b-container id="guide">
      <h1 class="page-title font-weight-bolder">
        <template v-if="is_faq">
          FAQ
        </template>

        <template v-else>
          Guide
        </template>
      </h1>

      <SectionInfo>
        <template v-if="is_faq">
          Frequently Asked Questions
        </template>

        <template v-else>
          From game installation to advanced game tricks and tips, here you can find guides and how-to's.
        </template>
      </SectionInfo>

      <div id="guide_container"
           class="d-flex flex-wrap flex-md-nowrap">
        <div id="guide_table" class="pt-5">
          <component :is="mq_gte_md ? 'vue-sticky-sidebar' : 'div' "
                     containerSelector="#guide_container"
                     :topSpacing="96">

            <scrollactive class="side-bar" :offset="80">
              <div class="h2">
                Contents
              </div>

              <ul>
                <li v-for="section in sections"
                    :key="'nav-' + section.id">
                  <a :href="'#' + section.id"
                     class="scrollactive-item" >
                     • {{section.title}}
                  </a>
                </li>
              </ul>
            </scrollactive>
          </component>
        </div>
        

        <div id="guide_sections"
             class="flex-grow-1">
          <div v-for="section in sections"
               :key="section.id"
               :id="section.id"
               class="section_wrapper">
            <!-- XXX: workaround for sticky nav
                 covering anchored section -->
            <div class="section" >
              <h3>{{section.title}}</h3>

              <div>
                <span v-html="section.content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </MainLayout>
</template>

<script>
import VueStickySidebar from "vue-sticky-sidebar";

import MainLayout from './components/MainLayout'
import SectionInfo from './components/SectionInfo'
import GuideContent from './mixins/GuideContent'

export default {
  name: 'Guide',

  mixins : [GuideContent],

  components: {
    MainLayout,
    SectionInfo,
    VueStickySidebar
  },

  computed : {
    is_faq () {
      const path = this.$route.path.split("/")
      return path[path.length-1] == 'faq'
    },

    sections () {
      return this.common_sections
                 .concat(this.is_faq ? this.faq_sections :
                                       this.guide_sections)
                 .filter(s => !s.disabled)
                 .sort((s1, s2) => s1.priority - s2.priority);
    }
  }
}
</script>

<style scoped>
h2, h3{
  color: var(--theme-color5);
  padding-bottom: 25px;
}

#guide{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1660px;
  padding-bottom: 5rem;
}

#main_layout.sm #guide,
#main_layout.xs #guide{
  padding: 25px 15px;
}

#guide_table {
  max-width: 350px;
  min-width: 350px;
  margin-right: 48px;
}

#main_layout.md #guide_table {
  min-width: 250px;
  max-width: 250px;
  margin-right: 16px;
}

.side-bar {
  background: var(--theme-color0);
  box-shadow: 0px 20px 30px var(--theme-color26);
  border-radius: 24px;
  max-width: 350px;
  min-width: 350px;
  padding: 12px 0;
}

#main_layout.md .side-bar {
  min-width: 250px;
  max-width: 250px;
}
#main_layout.sm #guide_table,
#main_layout.xs #guide_table,
#main_layout.sm .side-bar,
#main_layout.xs .side-bar {
  min-width: 100%;
  max-width: 100%;
  margin: 0;
}

.side-bar .h2 {
  font-weight: 900;
  padding: 0 2rem !important;
  margin-bottom: 1rem;
}

#main_layout.sm .side-bar .h2,
#main_layout.xs .side-bar .h2 {
  font-size: 1.5rem;
}

#main_layout.md .side-bar .h2 {
  font-size: 1.2rem;
  padding: 0 1rem !important;
}

ul {
  padding: 0;
  list-style-type: none;
}

ul li a{
  height: 48px;
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 1.5rem;
  position: relative;
  color: var(--theme-color10);
  font-size: 1.125rem;
  letter-spacing: -1px;
}

#main_layout.sm ul li a,
#main_layout.xs ul li a {
  font-size: 1rem;
}

#main_layout.md ul li a {
  font-size: 0.875rem;
  padding: 1rem;
}

ul li a.is-active {
  color: var(--theme-color9);
  font-weight: 900;
}
ul li a.is-active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(270deg, var(--theme-color9) 7.9%, var(--theme-color25) 100%);
  opacity: .1;
}

.section_wrapper {
  padding-bottom: 75px;
}
.section_wrapper:not(:first-child) {
  padding-top: 70px;
}
.section h3 {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--theme-color10);
}

#main_layout.sm .section,
#main_layout.xs .section{
  padding: 15px;
}
</style>
