<template>
  <CenterLayout section="reset">
    <div id="reset">
      <h1>Reset Password</h1>

      <div id="password_row">
        <div class="input_label">Password</div>

        <b-form-input type="password" v-model="password" />

        <div v-if="errors.weak_password" class="error">
          Weak password
        </div>
      </div>

      <div id="confirm_password_row">
        <div class="input_label">Confirm Password</div>

        <b-form-input type="password" v-model="confirm_password" />

        <div v-if="errors.password_mismatch" class="error">
          Passwords do not match
        </div>
      </div>

      <div id="reset_wrapper">
        <b-button :disabled="!is_valid" @click="submit">
          Reset
        </b-button>
      </div>
    </div>
  </CenterLayout>
</template>

<script>
import CenterLayout from './components/CenterLayout'
import ServerAPI from './mixins/server_api'
import TriggersModal from './mixins/triggers_modal'
import MaintenanceMode from './mixins/maintenance_mode'

import util from './util'

export default {
  name: 'ResetPassword',

  mixins : [
    ServerAPI,
    TriggersModal,
    MaintenanceMode
  ],

  components: {
    CenterLayout
  },

  props : {
    code : {
      type : String,
      required : true
    }
  },

  data () {
    return {
      password : null,
      confirm_password : null,
      errors : {
        weak_password : false,
        password_mismatch : false
      }
    }
  },

  computed : {
    any_error (){
      const errors = Object.keys(this.errors);
      return errors.some((e) => this.errors[e]);
    },

    is_valid () {
      return !!this.password         &&
             !!this.confirm_password &&
             !this.any_error;
    }
  },

  watch : {
    password () {
      this.errors.weak_password =
        !!this.password && util.is_weak_password(this.password);

      this.errors.password_mismatch =
        !!this.password         &&
        !!this.confirm_password &&
        this.password != this.confirm_password;
    },

    confirm_password () {
      this.errors.password_mismatch =
        !!this.password         &&
        !!this.confirm_password &&
        this.password != this.confirm_password;
    }
  },

  methods : {
    submit (){
      this.reset_password(this.code, this.password)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this));
    },

    success () {
      this.on_modal_hide(function(){
        this.$router.push("/");
      }.bind(this))

      this.show_modal({
        id : 'reset_password_confirmed',
        message : 'Your password has been reset, you may now login',
        is_error : false
      })
    },

    failed () {
      this.show_modal({
        id : 'reset_password_error',
        message : 'Could not reset password',
        is_error : true
      });
    }
  },

  mounted (){
    if(this.maintenance_mode)
      this.show_maintenance_dialog();
  }
}
</script>

<style scoped>
#reset{
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

#main_layout.md #reset,
#main_layout.sm #reset,
#main_layout.xs #reset{
  width: unset;
}

#reset h1{
  font-family: var(--theme-font3);
  text-align: center;
}

#main_layout.xs #reset h1{
  font-size: 36px;
}

#reset .input_label{
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 19.5px;
  color: var(--theme-color10);
  margin-bottom: 10px;
}

#reset .error{
  color: red;
  font-size: 12px;
  font-style: italic;
  margin-left: 10px;
}

#confirm_password_row{
  margin-top: 10px;
}

#reset_wrapper{
  margin-top: 15px;
  text-align: right;
}
</style>
