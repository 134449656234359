<template>
  <CenterLayout section="register">
    <div id="register">
      <div id="title">
        Register New Ledger City Account
      </div>

      <div id="required_info">
        <span class="required">*</span>
        <span> Required field</span>
      </div>

      <div id="username_row" class="register_row">
        <div class="subtitle">
          <span>USER NAME</span>
          <span class="required"> *</span>
        </div>

        <b-form-input v-model="username"
                  placeholder="Pick a username" />

        <div v-if="errors.handle_too_short" class="error">
          User Name is too short
        </div>

        <div v-if="errors.handle_too_long" class="error">
          User Name is too long
        </div>

        <div v-if="errors.handle_has_spaces" class="error">
          User Name cannot have spaces
        </div>

        <div v-if="errors.handle_has_invalid_characters" class="error">
          User Name cannot have special characters
        </div>
      </div>

      <div id="email_row" class="register_row">
        <div class="subtitle">
          <span>EMAIL ADDRESS</span>
          <span class="required"> *</span>
        </div>

        <b-form-input v-model="email"
                      placeholder="Enter your email" />

        <div v-if="errors.invalid_email" class="error">
          Invalid email
        </div>
      </div>

      <div id="password_row" class="register_row">
        <div class="subtitle">
          <span>PASSWORD</span>
          <span class="required"> *</span>
        </div>

        <b-form-input v-model="password"
                      type="password"
                      placeholder="Enter Password" />

        <div v-if="errors.weak_password" class="error">
          Weak password
        </div>
      </div>

      <div id="confirm_password_row" class="register_row">
        <div class="subtitle">
          <span>CONFIRM PASSWORD</span>
          <span class="required"> *</span>
        </div>

        <b-form-input v-model="confirm_password"
                      type="password"
                      placeholder="Confirm Password" />

        <div v-if="errors.password_mismatch" class="error">
          Passwords do not match
        </div>
      </div>

      <div id="select_row" class="register_row">
        <div class="subtitle">
          <span>SELECT:</span>
        </div>
      </div>

      <div id="legal_row" class="register_row_small">
        <b-form-checkbox v-model="legal">
          <span>I accept the <router-link to="/terms" target="_blank">Terms of Service</router-link> and <router-link to="/privacy" target="_blank">Privacy Policy</router-link></span>
          <span class="required">*</span>
        </b-form-checkbox>
      </div>

      <div id="news_row" class="register_row_small">
        <b-form-checkbox v-model="news">
          Send me news and updates
        </b-form-checkbox>
      </div>

      <div id="submit_row" class="register_row">
        <b-button :disabled="!is_valid" @click="register_clicked">
          Register
        </b-button>
      </div>
    </div>
  </CenterLayout>
</template>

<script>
import ServerAPI       from './mixins/server_api'
import TriggersModal   from './mixins/triggers_modal'
import MaintenanceMode from './mixins/maintenance_mode'
import CenterLayout    from './components/CenterLayout'
import lc              from './config/lc'
import util            from './util'

export default {
  name: 'Register',

  mixins : [
    ServerAPI,
    TriggersModal,
    MaintenanceMode
  ],

  components : {
    CenterLayout
  },

  data () {
    return {
      username : '',
      email : '',
      password : '',
      confirm_password : '',
      legal : false,
      news : false,

      errors : {
        handle_too_short : false,
        handle_too_long : false,
        handle_has_spaces : false,
        handle_has_invalid_characters : false,
        invalid_email : false,
        weak_password : false,
        password_mismatch : false
      }
    }
  },

  computed : {
    any_error () {
      const errors = Object.keys(this.errors);
      return errors.some((e) => this.errors[e]);
    },

    is_valid () {
      return !!this.username         &&
             !!this.email            &&
             !!this.password         &&
             !!this.confirm_password &&
             !!this.legal            &&
             !this.any_error;
    }
  },

  watch : {
    username () {
      this.errors.handle_too_short =
        !!this.username &&
          this.username.length < lc.min_handle_length;

      this.errors.handle_too_long =
        !!this.username &&
          this.username.length > lc.max_handle_length;

      this.errors.handle_has_spaces =
        !!this.username && /\s/g.test(this.username);

      this.errors.handle_has_invalid_characters =
        !!this.username &&
        lc.invalid_chars.some((c) => this.username.includes(c));
    },

    email () {
      this.errors.invalid_email =
        !!this.email && !util.is_valid_email(this.email);
    },

    password () {
      this.errors.weak_password =
        !!this.password && util.is_weak_password(this.password);

      this.errors.password_mismatch =
        !!this.password         &&
        !!this.confirm_password &&
        this.password != this.confirm_password;
    },

    confirm_password () {
      this.errors.password_mismatch =
        !!this.password         &&
        !!this.confirm_password &&
        this.password != this.confirm_password;
    }
  },

  methods : {
    register_clicked (){
      this.register({
          handle : this.username,
           email : this.email,
        password : this.password,
        legal_agree: this.legal,
        mailing_list : this.news
      }).then(this.success.bind(this))
        .catch(this.failed.bind(this));
    },

    success () {
      // Nativate to landing when modal is closed
      this.on_modal_hide(function() {
        this.$router.push("/");
      }.bind(this));

      const msg = "Please check your email to complete your registration.";
      this.show_modal({
        id : 'registered',
        message : msg
      });
    },

    failed (err) {
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'register_error',
        message : 'Could not register: ' + msg,
        is_error : true
      })
    }
  },

  mounted () {
    if(this.maintenance_mode)
      this.show_maintenance_dialog();
  },

  created () {
    if(this.$store.state.registration.email)
      this.email = this.$store.state.registration.email;
    if(this.$store.state.registration.password)
      this.password = this.$store.state.registration.password;
    this.$store.commit('clear_registration');
  }
}
</script>

<style scoped>
#register{
  box-shadow: 0 0.125rem 0.25rem var(--theme-color26);
  padding: 5px;
}

#required_info{
  text-align: right;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 12px;
}

.register_row{
  padding: 15px;
}

.register_row_small{
  padding: 5px 15px;
}

#title{
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--theme-color5);
  font-weight: 600;
}

#main_layout.xs #title{
  padding: 15px;
}

.subtitle{
  color: #555;
  font-weight: 700;
  font-size: 13px;
}

.required{
  color: red;
}

#submit_row .btn{
  width: 100%;
}
</style>
