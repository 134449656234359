<template>
  <MainLayout section="landing">
    <div id="landing">
      <Splash       />
      <Gallery      />
      <Guide        />
      <Blog         />
      <Contact      />
    </div>
  </MainLayout>
</template>

<script>
import MainLayout   from './components/MainLayout'
import Splash       from './components/landing/Splash'
import Gallery      from './components/landing/Gallery'
import Guide        from './components/landing/Guide'
import Blog         from './components/landing/Blog'
import Contact      from './components/landing/Contact'

export default {
  name: 'Landing',

  components: {
    MainLayout,
    Splash,
    Gallery,
    Guide,
    Blog,
    Contact
  }
}
</script>
