<template>
  <MainLayout section="bid">
    <h1>Bid on Building</h1>

    <b-form @submit="submit">
      <b-row>
        <b-col id="building_col">
          <b-form-group label="Building"
                        description="Building to bid on"
                        label-for="building"
                        :invalid-feedback="building_error"
                        :state="building_state">
            <b-form-select v-model="building" :options="building_options" id="building" />
          </b-form-group>

          <div id="auction_close" v-if="auction">Auction ends at <b>{{auction_close}}</b></div>
        </b-col>

        <b-col>
          <div id="building_preview">
            TODO: building preview
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Account"
                        description="Account to move to building"
                        label-for="account"
                        :invalid-feedback="account_error"
                        :state="account_state">
            <b-form-select v-model="account" :options="account_options" id="account" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-button id="claim_button"
                    :class="account_state ? 'error' : ''"
                    href="/profile#claim"
                    :disabled="account_state"
                    variant="warning">
            Claim One &gt;
          </b-button>
        </b-col>
      </b-row>

      <b-row id="bid_row">
        <b-col id="bid_title">
          <h2>BID</h2>
          <div v-if="minBid > 0">
            (Minimum: {{minBid}} LCC)
          </div>
        </b-col>

        <b-col>
          <b-form-group id="bid_group"
                        label-for="bid"
                        :invalid-feedback="bid_error"
                        :state="bid_state">
            <b-form-input id="bid" v-model="bid" type="number" :min="minBid" /> LCC
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col id="terms">
          <div>By clicking "Submit" you are agreeing to the <router-link to="/terms">Ledger City Terms of Service</router-link>.</div>
          <div>LCC will only be deduced from your account if you are the winner of the auction.</div>
        </b-col>
      </b-row>

      <b-row>
        <b-col id="submit_col">
          <b-button type="submit" variant="primary" :disabled="submit_disabled">Submit</b-button>
        </b-col>
      </b-row>
    </b-form>
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout'

import Authentication from './mixins/authentication'
import ServerAPI from './mixins/server_api'
import TriggersModal from './mixins/triggers_modal'
import MaintenanceMode from './mixins/maintenance_mode'

import CityService from './mixins/CityService'

export default {
  name: 'Bid',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal,
    MaintenanceMode,
    CityService
  ],

  components : {
    MainLayout,
  },

  data() {
    return {
      building : null,
      auction : null,
      account : null,
      bid : null
    }
  },

  computed : {
    building_options() {
      return this.auctioned_buildings.map(b => {
        return {
          text : b.neighborhood.name + ": " + b.name,
          value : b.id
        }
      })
    },

    building_ids () {
      return this.auctioned_buildings.map(b => b.id)
    },

    building_state() {
      return this.building != null &&
             this.building_ids.includes(this.building);
    },

    building_error() {
      if(this.building == null) return "Select building"
      return "Invalid building";
    },

    selected_building () {
      return this.auctioned_buildings.find(b => b.id == this.building);
    },

    ///

    auction_close () {
      return new Date(Date.parse(this.auction.nextClose)).toLocaleString();
    },

    ///

    valid_accounts(){
      if(!this.building || !this.building.requirements)
        return this.$store.state.user.Accounts;

      const reqs = this.building.requirements;

      return this.$store.state.user.Accounts.filter(function(a){
        return (!reqs.min_balance || a.balance >= reqs.min_balance) &&
               (!reqs.max_balance || a.balance <= reqs.max_balance);
      }.bind(this));
    },

    account_options(){
      return this.valid_accounts.map(a => a.id);
    },

    account_ids () {
      return this.valid_accounts.map(a => a.id);
    },

    account_state () {
      return this.account != null &&
             this.account_ids.includes(this.account);
    },

    account_error () {
      if(this.account == null) return "Select account"
      return "Invalid account";
    },

    ///

    minBid () {
      return this.auction?.minBid || 0;
    },

    bid_state() {
      return this.bid != null && this.bid >= this.minBid && this.bid <= this.$store.state.user.lcc;
    },

    bid_error() {
      if(this.bid == null) return "Specify bid";
      if(this.bid < this.minBid) return "Does not meet minimum";
      if(this.bid > this.$store.state.user.lcc) return "You do not have enough LCC";
      return "Invalid bid";
    },

    ///

    submit_disabled () {
      return !this.building_state ||
             !this.account_state  ||
             !this.bid_state;
    }
  },

  watch : {
    building () {
      this.auction = null;
      this.account = null

      if(this.bid < this.minBid)
        this.bid = null;

      if(this.building != null)
        this.get_auction(null, this.building)
            .then(function(r) {
              this.auction = r.body;
            }.bind(this))
    },

    account_options () {
      if(this.$route.query.account)
        this.account = this.$route.query.account;
    }
  },

  methods: {
    submit(event) {
      event.preventDefault()
      this.place_bid({
        building : this.building,
        account : this.account,
        bid : parseInt(this.bid)
      }).then(this.success.bind(this))
        .catch(this.failed.bind(this))
    },

    success () {
      this.get_user();
      this.show_modal({
        id : 'auction_bid_success',
        message : 'Successfully placed bid',
        is_error : false
      })
    },

    failed () {
      this.show_modal({
        id : 'auction_bid_error',
        message : 'Error when placing bid',
        is_error : true
      })
    },
  },

  mounted () {
    if(this.maintenance_mode)
      this.show_maintenance_dialog();

    if(this.$route.query.building)
      this.building = parseInt(this.$route.query.building);
    if(this.$route.query.bid)
      this.bid = parseInt(this.$route.query.bid);
  },

  created () {
    if(!this.logged_in)
      this.$router.push("/")
  }
}
</script>

<style scoped>
h1{
  text-align: center;
  margin-top: 25px;
  width: 100%;
}

h2{
  text-align: center;
  width: 100%;
}

form{
  margin: 25px 75px;
}

.form-group{
  width: 100%;
}

.row{
  margin: 50px;
}

.col{
  display: flex;
  align-items: center;
}

#building_col{
  flex-direction: column;
  justify-content: center;
}

#auction_close{
  margin-left: 15px;
}

#building_preview{
  height: 500px;
  width: 500px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

#claim_button{
  margin-bottom: 35px;
}

#claim_button.error{
  margin-bottom: unset;
}

#bid_row{
  border: 1px solid black;
  border-radius: 10px;
  padding: 75px;
}

#bid_title{
  flex-direction: column;
}

#big_group{
  margin-top: 1rem;
}

#bid{
  display: unset;
  width: unset;
}

#terms{
  font-style: italic;
  flex-direction: column;
}

#submit_col{
  justify-content: center;
}
</style>
