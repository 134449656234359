<template>
  <MainLayout section="status">
    <b-row class="section_header">
      <b-col>
        <h1>Status</h1>
      </b-col>
    </b-row>

    <b-row v-if="have_status" id="status">
      <b-col>
        <b-card>
          Users: {{status_.users}}
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          Owned Accounts: {{status_.owned_accounts}}
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          Current Block: {{status_.block_current}}
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          Processed Block: {{status_.block_processed}}
        </b-card>
      </b-col>
    </b-row>

    <b-row class="section_header">
      <b-col>
        <h1>User</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="input_col">
        <b-form-input v-model="user_input" placeholder="Handle or email" />
      </b-col>
    </b-row>

    <b-row v-if="have_user_error">
      <b-col>
        <span class="error">{{user_error}}</span>
      </b-col>
    </b-row>

    <b-row v-if="have_user">
      <b-col>
        <renderjson :data="user" level="2" />
      </b-col>
    </b-row>

    <b-row class="section_header">
      <b-col>
        <h1>Account</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="input_col">
        <b-form-input v-model="account_input" placeholder="Address" />
      </b-col>
    </b-row>

    <b-row v-if="have_account_error">
      <b-col>
        <span class="error">{{account_error}}</span>
      </b-col>
    </b-row>

    <b-row v-if="have_account">
      <b-col>
        <renderjson :data="account" level="2" />
      </b-col>
    </b-row>

    <b-row>
      <b-col><br/></b-col>
    </b-row>
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout'
import Authentication from './mixins/authentication'
import ServerAPI from './mixins/server_api'

import renderjson from './vendor/renderjson/renderjson.vue'

// MS between status refreshes
const REFRESH_TIME = 5000;

// Min number of characters to search
const MIN_CHARS = 5;

export default {
  name: 'Status',

  mixins : [
    Authentication,
    ServerAPI,
  ],

  components : {
    MainLayout,
    renderjson
  },

  data () {
    return {
      user_input : null,
      account_input : null,

      status_ : null,
      user : null,
      account : null,

      user_error : null,
      account_error : null
    }
  },

  watch : {
    user_input () {
      if(this.user_input.length < MIN_CHARS) return;
      this.get_user();
    },

    account_input () {
      if(this.account_input.length < MIN_CHARS) return;
      this.get_account();
    }
  },

  computed : {
    have_status () {
      return !!this.status_;
    },

    have_user_input () {
      return !!this.user_input;
    },

    have_user () {
      return !!this.user;
    },

    have_user_error () {
      return !!this.user_error;
    },

    have_account_input () {
      return !!this.account_input;
    },

    have_account () {
      return !!this.account;
    },

    have_account_error () {
      return !!this.account_error;
    }
  },

  methods : {
    get_status_ () {
      this.get_status()
          .then(function(response){
            this.status_ = response.body;

          }.bind(this)).catch(function(){
            this.$router.push("/")
          }.bind(this));
    },

    get_user () {
      this.get_user_status(this.user_input)
          .then(function(response){
            this.user = response.body;
            this.user_error = null;

          }.bind(this)).catch(function(err){
            this.user = null;
            this.user_error = err.body.error;

          }.bind(this))
    },

    get_account () {
      this.get_account_status(this.account_input)
          .then(function(response){
            this.account = response.body;
            this.account_error = null;

          }.bind(this)).catch(function(err){
            this.account = null;
            this.account_error = err.body.error;

          }.bind(this))
    }
  },

  mounted () {
    this.get_status_();

    this.interval =
      setInterval(function() {
        this.get_status_();
      }.bind(this), REFRESH_TIME);
  },

  created () {
    if(!this.logged_in)
      this.$router.push("/")
  },

  destroyed () {
    if(this.interval)
      clearInterval(this.interval);
  }
}
</script>

<style scoped>
.section_header{
  padding: 25px;
  padding-bottom: 0px;
  text-align: center;
  text-decoration: underline;
}

#status{
  padding: 25px;
  padding-top: 0px;
}

.input_col{
  display: flex;
  justify-content: center;
}

#main_layout.xl input,
#main_layout.lg input,
#main_layout.md input {
  width: 50%;
}

pre{
  background: black;
  padding: 5px;
  border-radius: 5px;
}

#main_layout.xl pre,
#main_layout.lg pre,
#main_layout.md pre {
  margin: 25px;
}
</style>
