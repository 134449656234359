<template>
  <MainLayout section="gallery">
    <div id="gallery">
      <h1>Gallery</h1>

      <p>Blockchain is changing the world of finance. Now it's time to revolutionalize the virtual world... one player at a time...</p>

      <b-container>
        <b-row class="mb-3">
          <b-col>
            <GalleryImage id="1"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="2"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="3"
                          :subtext="true"
                          :expandable="true" />
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <GalleryImage id="4"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="5"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="6"
                          :subtext="true"
                          :expandable="true" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <GalleryImage id="7"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="8"
                          :subtext="true"
                          :expandable="true" />
          </b-col>

          <b-col>
            <GalleryImage id="9"
                          :subtext="true"
                          :expandable="true" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout   from './components/MainLayout'
import GalleryImage from './components/GalleryImage'

export default {
  name: 'Gallery',

  components : {
    MainLayout,
    GalleryImage
  }
}
</script>

<style scoped>
#gallery{
  padding: 25px 15px;
}

h1{
 text-align: center;
}

p{
 text-align: center;
 margin-bottom: 25px;
 color: var(--theme-color14);
 font-weight: 900;
}
</style>
