<template>
  <CenterLayout section="confirm">
    <div id="confirm">
      <h2>Confirming Email... <b-spinner type="grow" /></h2>
    </div>
  </CenterLayout>
</template>

<script>
import CenterLayout from './components/CenterLayout'
import ServerAPI  from './mixins/server_api'
import TriggersModal from './mixins/triggers_modal'
import MaintenanceMode from './mixins/maintenance_mode'

export default {
  name: 'ConfirmEmail',

  mixins : [
    ServerAPI,
    TriggersModal,
    MaintenanceMode
  ],

  components: {
    CenterLayout
  },

  props : {
    code : {
      type : String,
      required : true
    }
  },

  methods : {
    send_request (){
      // Nativate to landing when modal is closed
      this.on_modal_hide(function() {
        this.$router.push("/");
      }.bind(this));

      // Send request & handle result
      this.confirm_email({code : this.code})
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    success (){
      this.show_modal({
        id : 'registration_confirmed',
        message : 'Confirmed registration... welcome to Ledger City!',
        is_error : false
      })
    },

    failed (){
      this.show_modal({
        id : 'regisration_error',
        message : 'Could not confirm registration',
        is_error : true
      });
    }
  },

  mounted : function(){
    if(this.maintenance_mode)
      this.show_maintenance_dialog();
    else
      this.send_request()
  }
}
</script>

<style scoped>
#confirm{
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--theme-font4);
}
</style>
