// Ledger City app configuration
const HTTP_PORT  = process.env.LCAPI_HTTP_PORT  || 3000;
const HTTPS_PORT = process.env.LCAPI_HTTPS_PORT || 3001;

const URI        = process.env.LC_URI           || "https://ledgercitygame.com";
//const URI        = process.env.LC_URI           || "http://localhost:8080";
const HOST = new URL(URI).host;

// XXX: keep in sync w/ config/auth.js!
const LCC_OPERATIONAL_ADDRESS = process.env.LCC_OPERATIONAL_ADDRESS || 'rDsqnL7ZqXAaqWXUjsaW2pGGmGxJDV7YdT';
const LCC_ISSUER_ADDRESS  = process.env.LCC_ISSUER_ADDRESS  || 'rhFGZnmDBFJ3zdFKnUcpJhXUZzRP2qdEWd';
const LC_PURCHASE_ADDRESS = process.env.LC_PURCHASE_ADDRESS || 'rpqaQHvhvswYynLoqGpPwdbguKv7jnfYRe';

const VERSION = process.env.LC_VERSION || "v1";

module.exports = {
  // Enable config validation
  validate_config : true,

  // Enable blockchain walllets validation (online)
  validate_blockchain_wallets : true,

  // Log params
  logfile  : "lc.log",
  loglevel : "verbose",
  logtest  : false, // if true log messages will be displayed when running tests

  app_name : 'Ledger City',

  // URI refrenced in emails and user communication
  uri : URI,

  // Hostname portion of URI
  host : HOST,

  // Webserver host and port
  // Used for both HTTP/HTTPS and WS/WSS
  http_port  : HTTP_PORT,
  https_port : HTTPS_PORT,

  // Minimum/maximum user handle we'll accept
  min_handle_length : 5,
  max_handle_length : 12,

  // Minimum/maximum corporation title handle we'll accept
  min_corporation_title_length : 5,
  max_corporation_title_length : 12,

  // Invalid handle and title characters
  invalid_chars : [
    '!', '@', '#', '$', '%',
    '^', '&', '*', '(', ')',
    '+', '=', '{', '}', '[', ']',
    ':', ';', "'", '"', '<', '>',
    ',', '/', "\\", '?', '|'
  ],

  // Minimum password score we'll accept
  min_password_score : 3,

  account_association_amount: {
    min: 0.01,    // in xrp
    max: 0.99,    // in xrp
    precision : 2 // decimal places
  },

  // Range in which user destination tags will be generated
  destination_tag : {
    min : 1000000000,
    max : 4000000000
  },

  // In milliseconds:
  timeouts : {
              auth : 3600000,   // 1 hour
       association : 259200000, // 3 days
      registration : 259200000, // 3 days
    password_reset : 3600000,   // 1 hour
     lcc_withdrawl : 3600000,   // 1 hour
          db_query : 3000       // 3 seconds
  },

  // Seconds after which an outage is reported (in ms)
  outage_timeouts : {
       monitor_blockchain : 10000,   // 10 seconds
           register_users : 3000,    // 3 seconds
          reset_passwords : 3000,    // 3 seconds
         reset_privileges : 3000,    // 3 seconds
           lcc_withdrawls : 3000,    // 3 seconds
             lcc_deposits : 3000,    // 3 seconds
      associated_accounts : 3000,    // 3 econds
             run_auctions : 120000,  // 2 minutes
          monitor_wallets : 4500000, // 1 hour 15 minutes
        lookup_xchg_rates : 420000,  // 7 minutes
             cleanup_logs : 3000,    // 3 seconds
              run_backups : 25200000 // 7 hours
  },

  // Other thresholds which outages are reported
  outage_thresholds : {
      block_difference : 1,
    operational_wallet : 100000 // min LCC balance
  },

  // Number of days to retain log records
  log_retention_days : 7,

  initial_profile : {
  },

  workers : {
    maint : 17
  },

  // In milliseconds:
  worker_delays : {
    monitor_blockchain : 1000,     // 1 second
        blockchain_err : 1000,     // 1 second
             heartbeat : 10000,    // 10 seconds - heartbeat
                 maint : 1000,     // 1 second
               backups : 21600000, // 6 hours
       monitor_wallets : 3600000,  // 1 hour
             xchg_rate : 300000,   // 5 minutes
                outage : 3600000,  // 1 hour
        simulate_world : 300000,   // 5 minutes
           run_auctions : 60000,   // 1 minute
  },

  // Directory which backups will be written to
  backup_dir : '/lc/backups',

  // Public wallets
  wallets : {
    purchase : LC_PURCHASE_ADDRESS, // receive XRP payments
    issuer : LCC_ISSUER_ADDRESS, // LCC issuer
    operational : LCC_OPERATIONAL_ADDRESS // receive LCC deposits & send LCC withdrawls
  },

  // How much LCC the user receives after claiming an account
  initial_lcc : 5,

  // How much one LCC costs
  lcc_price: 2.5, // dollars

  // Percentage of asset price (in LCC)
  // that will be returned to user upon removal
  asset_refund_percent : 0.5,

  // Percentage of taxable events collected in taxes
  tax_rate : 0.23,

  // Maximum number of LCC withdrawl requests
  // user can have open at a time
  max_withdrawl_requests: 5,

  // Max number of non-completed associate account requests
  // user can have open at a time
  max_open_account_associations : 3,

  // Maximum number of blocks after the current one to
  // allow a blockchain transaction
  max_transaction_blocks : 100, // at ~3 seconds per block -> ~5 minutes

  // Issued currency code
  issued_currency: "LCC",

  //API version
  version : VERSION,

  // Metadata keys which client can retrieve
  meta : {
    all : [
      '%_block_current',
      '%_block_processed',
      'xchg_rate_%_usd',
      'current_weather'
    ]
  },

  // Number of weather patterns to select from
  weather_patterns : 29,

  // Min / max time which to convert weather (in ms)
  weather_cycle : {
    min : 300000, // 5 minutes
    max : 600000  // 10 minutes
  },

  // Max number of search results
  search : {
    max_results : 10,
    min_query : 5
  }
}
