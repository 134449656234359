<template>
  <MainLayout section="download">
    <div id="download">
      <div id="container">
        <div id="left">
          <h1>Download</h1>

          <p>Here are the latest Ledger City releases for the supported platforms.</p>

          <div id="targets">
            <a href="/clients/LedgerCity-0.3.6.dmg"><img src="@/assets/images/download/mac.png" alt="Mac Download"/></a>
            <a href="/clients/LedgerCitySetup-0.3.6.exe"><img src="@/assets/images/download/windows.png" alt="Windows Download"/></a>
            <a href="https://play.google.com/store/apps/details?id=com.devnullprod.lcg"><img src="@/assets/images/download/android.png" alt="Google Play (Android)"/></a>
          </div>

          <p id="trouble">Having trouble? Checkout our handy <a href="/guide">guides</a></p>
        </div>

        <div id="right">
          <img src="@/assets/images/download/MacBookPro.png" alt="Laptop Background"/>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout'

// TODO read version from public/config.json

export default {
  name: 'Download',

  components : {
    MainLayout
  }
}
</script>

<style scoped>
#download {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#download #container{
  background: var(--theme-color7);
  height: 600px;
  width: 90%;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
}

#main_layout.md #download #container,
#main_layout.sm #download #container,
#main_layout.xs #download #container{
  height: 90%;
  flex-direction: column;
  margin: 25px 0;
}

#download #left{
  padding: 25px;
}

#download #right{
  padding: 25px;
  padding-right: 0;
}

#main_layout.md #download #right,
#main_layout.sm #download #right,
#main_layout.xs #download #right{
  padding: 15px;
  padding-right: 0;
  align-self: flex-end;
}


h1{
  font-weight: bold;
}

p{
  font-size: 20px;
}

#main_layout.md p{
  font-size: 16px;
}

#main_layout.sm p,
#main_layout.xs p{
  font-size: 18px;
}

#download #targets{
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#download #targets a{
  padding: 15px;
  margin: auto;
}

#download #targets img{
  width: 240px;
}

#download #right img{
  width: 550px;
  max-width: 100%;
}

#download #trouble{
  text-align: right;
  font-size: 90%;
}
</style>
