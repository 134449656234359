<template>
  <MainLayout section="post">
    <div id="post_header">
      <b-container>
        <img :src="post_img" class="post-img" :alt="title" />

        <div id="post_info" class="px-md-5 py-2 py-md-4 justify-content-center">
          <div>
            <img src="@/assets/blog/user-icon.svg" width="24" height="24" alt="author" />

            {{ post.author || "Ledger City" }}
          </div>

          <div>
            <img src="@/assets/blog/calendar-icon.svg" width="24" height="24" alt="timestamp" />

            {{ date_str }}
          </div>

          <!-- TODO: time-to-read and category -->
        </div>
      </b-container>
    </div>

    <b-container id="post_container">
      <div id="post">
        <h1 id="post_title" class="my-4">{{ post.title }}</h1>
        <router-view />
        <hr />
      </div>

      <div id="share">
        <div><b>Share this Post:</b></div>
        <div id="share-icons">
          <a :href="'https://twitter.com/intent/tweet?url=' + url">
            <img src="./assets/images/icons/twitter.webp" alt="share on twitter" />
          </a>

          <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + url">
            <img src="./assets/images/icons/facebook.webp" alt="share on facebook" />
          </a>

          <a
            :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + url"
          >
            <img src="./assets/images/icons/linkedin.webp" alt="share on linkedin" />
          </a>

          <a :href="'mailto:my@friend.com?&subject=&body=' + url">
            <img src="./assets/images/icons/email.webp" alt="share via email" />
          </a>
        </div>
      </div>
    </b-container>

    <b-container fluid id="recent_posts_container">
      <RecentPosts />
    </b-container>
  </MainLayout>
</template>

<script>
import MainLayout from "./components/MainLayout";
import RecentPosts from "./components/RecentPosts";
import HasPost from "./mixins/HasPost";

export default {
  name: "Post",

  mixins: [HasPost],

  components: {
    MainLayout,
    RecentPosts,
  },
};
</script>

<style scoped>
#post_header {
  position: relative;
}
#post_header:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  top: 45%;
  transform: translateY(-50%);
  background: var(--theme-color9);
  z-index: 0;
}
#main_layout.sm #post_header:before,
#main_layout.xs #post_header:before {
  height: 60px;
  top: 35%;

}
#post_header .post-img {
  position: relative;
  width: 100%;
  z-index: 1;
}
#post_header #post_info {
  display: flex;
}
#post_header #post_info > div {
  flex-basis: 25%;
  display: inline-flex;
  align-items: center;
  color: #6e6e7d;
}

#main_layout.sm #post_info > div,
#main_layout.xs #post_info > div {
  flex-basis: 50%;
  font-size: 0.75rem
}

#post_header #post_info > div img {
  margin-right: 16px;
}

#post_container {
  padding-bottom: 4rem;
}

#post_container #post_title {
  font-weight: 900;
  font-size: 3rem;
}

#main_layout.sm #post_title,
#main_layout.xs #post_title {
  font-size: 2rem;
}

#recent_posts_container {
  max-width: 1660px;
  padding-bottom: 4rem;
}
</style>

<style>
#post_container #post h2,
#post_container #post h1 {
  font-size: 2rem;
  font-weight: 900;
  margin: 1rem 0;
}
#main_layout.sm  #post :is(h1, h2),
#main_layout.xs  #post :is(h1, h2) {
  font-size: 1.5rem;
}
#post_container #post p {
  font-size: 1rem;
  font-weight: 500;
}
#post_container #post img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

#post_container #post .img-caption {
  text-align: center;
  margin-bottom: 1.25rem;
  font-style: italic;
}

#post_container #post .img-row{
  display: flex;
}

#main_layout.sm #post_container #post .img-row,
#main_layout.xs #post_container #post .img-row{
  flex-direction: column;
}

#post_container #post .img-row img,
#post_container #post .img-row a{
  padding: 0 5px;
}

#main_layout.sm #post_container #post .img-row img,
#main_layout.xs #post_container #post .img-row img,
#main_layout.sm #post_container #post .img-row a,
#main_layout.xs #post_container #post .img-row a{
  padding: 5px 0;
}
</style>
