<template>
  <MainLayout section="blog">
    <b-container id="blog">
      
      <h1 class="page-title font-weight-bolder">
        Blog
      </h1>

      <SectionInfo>
        Recently From the Blog
      </SectionInfo>

      <b-row>
        <b-col xl="6" class="py-3" v-for="post in sorted_enabled_posts" :key="post.path">
          <BlogCard :path="post.path" />
        </b-col>
      </b-row>

      <!-- TODO: Add pagination -->
    </b-container>
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout'
import HasPosts from './mixins/HasPosts'
import SectionInfo from './components/SectionInfo'
import BlogCard from './components/BlogCard'

export default {
  name: 'Blog',

  mixins : [HasPosts],

  components: {
    MainLayout,
    BlogCard,
    SectionInfo
  }
}
</script>

<style scoped>
#blog{
  padding: 25px 15px;
}

#main_layout.xl #blog{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1660px;
  padding-bottom: 5rem;
}

#main_layout.sm #blog,
#main_layout.xs #blog {
  padding-bottom: 2rem;
}


</style>
